import _ from "lodash"

const generateFiltersfromPageData = (
  departmentVal,
  searchtypeVal,
  areaVal,
  bedVal,
  bathVal,
  minpriceVal,
  maxpriceVal,
  checkinVal,
  checkoutVal,
  amenitiesVal,
  adultVal,
  childrenVal,
  statusVal,
  buildingval
) => {
  // lets from the filters
  // here the search login lives

  const myfilter = []

  // facet filters
  // if ((Array.isArray(departmentVal))) {
  //     myfilter.push('(department:"' + (departmentVal).join('" OR department:"') + '")')
  // }
  const getDatesInRange = (startDate, endDate, timeStamp = true) => {
    // console.log("checkinVal1", startDate, endDate)
    const date = new Date(startDate)
    const enddate = new Date(endDate)
    // console.log("checkinVal11", date, enddate)
    const dates = []
    while (date <= enddate) {
      if (timeStamp)
        dates.push(Date.parse(new Date(date).toJSON().slice(0, 10)) / 1000)
      else {
        dates.push(new Date(date).toJSON().slice(0, 10))
      }
      date.setDate(date.getDate() + 1)
    }
    return dates
  }
  let checkinCheckoutDate = ""
  let checkInCheckOutTimestamp =
    checkinVal && checkoutVal ? getDatesInRange(checkinVal, checkoutVal) : ""
  checkInCheckOutTimestamp =
    checkinVal && checkoutVal
      ? `unAvailabilities!= ${checkInCheckOutTimestamp.join(
          " AND unAvailabilities!="
        )}`
      : ""

  if (departmentVal) {
    myfilter.push(`(department:${departmentVal})`)
  }

  if (searchtypeVal) {
    myfilter.push(`(search_type:${searchtypeVal})`)
  }
  // if(areaVal && areaVal !== "london"){
  //     myfilter.push("(area:"+areaVal+" OR post_code:"+areaVal+" OR display_address:"+areaVal+")")
  // }
  if (buildingval) {
    myfilter.push(`(building:${buildingval})`)
  }
  if (Array.isArray(statusVal)) {
    myfilter.push("(status:\"" + statusVal.join('" OR status:"') + '")')
  }
  // numberic filters
  if (bedVal && bedVal > 0) {
    myfilter.push(`(bedroom >= ${bedVal})`)
  }
  if (bathVal && bathVal > 0) {
    myfilter.push(`(bathroom >= ${bathVal})`)
  }
  if (minpriceVal && minpriceVal > 0 && maxpriceVal && maxpriceVal > 0) {
    myfilter.push(`(price >= ${minpriceVal} AND price <= ${maxpriceVal})`)
  } else if (maxpriceVal && maxpriceVal > 0) {
    myfilter.push(`(price <= ${maxpriceVal})`)
  } else if (minpriceVal && minpriceVal > 0) {
    myfilter.push(`(price >= ${minpriceVal})`)
  }
  if (adultVal || childrenVal) {
    const peopleCapacity = adultVal + childrenVal
    myfilter.push(`(peopleCapacity >= ${peopleCapacity})`)
  }
  if (checkinVal || checkoutVal) {
    checkinCheckoutDate = `${checkInCheckOutTimestamp}`
    myfilter.push(`(${checkinCheckoutDate})`)
  }
  if (typeof amenitiesVal !== "string" && amenitiesVal?.length > 0) {
    let queryStr = ""
    // const ama =

    const amenitiesOptions = {
      television: "TV",
      internet: "internet-access",
      washing_machine: "WashingMachine",
    }

    amenitiesVal?.forEach((amenity, index) => {
      // console.log("amenitiesOptions", amenitiesOptions[amenity], amenity)

      if (amenitiesOptions[amenity]) {
        queryStr += `amenities:${amenitiesOptions[amenity.replace(/-/g, "_")]}`
      } else {
        queryStr += `amenities:${amenity}`
      }
      if (index < amenitiesVal.length - 1) {
        queryStr += " AND "
      }
    })
    queryStr = `(${queryStr})`
    myfilter.push(queryStr)
  }
  // if(pageVal){
  //     myfilter.push("(page: "+(pageVal)+")")
  // }
  return myfilter.join(" AND ")
}

export default generateFiltersfromPageData
