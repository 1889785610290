// import {removeSpecialChar} from "../common/utils"
import propertyStatus from "../../search_config/status.json"
import departmentAllVal from "../../search_config/department.json"

const {
  removeSpecialChar,
} = require("@starberry/gatsby-theme-utils/Common/Utils")

const parseResultsUrl = (location) => {
  // split the URL
  if (location) {
    let pathUri_main = ""
    const departmentVal = "short-lettings"
    let searchtypeVal = ""
    let areaVal = ""
    let bedVal = ""
    let bathVal = ""
    let minpriceVal = ""
    let maxpriceVal = ""
    let checkinVal = ""
    let checkoutVal = ""
    let amenitiesVal = ""
    let adultVal = ""
    let childrenVal = ""
    let pageVal = "1"
    let buildingval = ""
    let sortVal = ""
    let soldVal = ""
    let statusVal = ""
    let layoutVal = ""
    const index_name = process.env.GATSBY_ALGOLIA_INDEX_NAME
    const propertyTypeVal = "residential"

    // let salespage  = location.pathname.indexOf("/property/for-sale") > -1
    // let lettingspage = location.pathname.indexOf("/property/to-rent") > -1
    // let newdevelopmentspage = location.pathname.indexOf("/property/new-homes/for-sale") > -1

    pathUri_main = location.pathname.split("/property/holiday-lets")
    searchtypeVal = "lettings"
    statusVal = propertyStatus[1].lettings

    // if (salespage === true) {
    //     pathUri_main = location.pathname.split("/property/for-sale")
    //     searchtypeVal = "sales"
    //     statusVal = propertyStatus[0].sales
    //     // departmentVal = departmentAllVal[0].sales
    // }
    // else if (lettingspage === true) {
    //     pathUri_main = location.pathname.split("/property/to-rent")
    //     searchtypeVal = "lettings"
    //     statusVal = propertyStatus[1].lettings
    //     // departmentVal = departmentAllVal[1].lettings
    // }
    // else if (newdevelopmentspage === true) {
    //     pathUri_main = location.pathname.split("/property/new-homes/for-sale")
    //     searchtypeVal = "sales"
    //     index_name = process.env.GATSBY_ALGOLIA_NEW_DEVELOPMENTS_INDEX_NAME
    //     propertyTypeVal = "new_developments"
    // }

    if (pathUri_main[1]) {
      // following could be regexp
      const pathUri = pathUri_main[1].split("/")
      // lets loop
      for (let vi = 1; vi <= pathUri.length; vi++) {
        // check for area
        if (typeof pathUri[vi] === "undefined") {
          continue
        }

        // Area
        if (pathUri[vi].indexOf("in-") >= 0) {
          areaVal = removeSpecialChar(pathUri[vi].replace("in-", ""), " ")
        }

        // Bedrooms
        if (pathUri[vi].indexOf("-and-more-bedrooms") >= 0) {
          bedVal = pathUri[vi].replace("-and-more-bedrooms", "")
        }

        // Bathrooms
        if (pathUri[vi].indexOf("-and-more-bathrooms") >= 0) {
          bathVal = pathUri[vi].replace("-and-more-bathrooms", "")
        }

        // Property type
        if (pathUri[vi].indexOf("type-") >= 0) {
          buildingval = pathUri[vi].replace("type-", "")
        }

        // Price
        if (
          pathUri[vi].indexOf("between-") >= 0 ||
          pathUri[vi].indexOf("above-") >= 0 ||
          pathUri[vi].indexOf("below-") >= 0
        ) {
          const priceFilt1 = pathUri[vi].split("above-")
          if (priceFilt1[1]) {
            minpriceVal = priceFilt1[1]
          }
          const priceFilt2 = pathUri[vi].split("below-")
          if (priceFilt2[1]) {
            maxpriceVal = priceFilt2[1]
          }
          const priceFilt3 = pathUri[vi].split("between-")
          if (priceFilt3[1]) {
            const priceFilt4 = priceFilt3[1].split("-and-")
            minpriceVal = priceFilt4[0]
            maxpriceVal = priceFilt4[1]
          }
        }

        if (pathUri[vi].indexOf("checkindate-") >= 0) {
          checkinVal = pathUri[vi].replace("checkindate-", "")
        }
        if (pathUri[vi].indexOf("checkoutdate-") >= 0) {
          checkoutVal = pathUri[vi].replace("checkoutdate-", "")
        }

        // amenities
        if (pathUri[vi].indexOf("with-features-") >= 0) {
          const amenityValFilt = pathUri[vi].replace("with-features-", "")
          amenitiesVal = amenityValFilt.split("-and-")
        }

        // Adults
        if (pathUri[vi].indexOf("-adults-or-more") >= 0) {
          adultVal = parseInt(pathUri[vi].replace("-adults-or-more", ""))
        }
        // Adults

        // Childrens
        if (pathUri[vi].indexOf("-childrens-or-more") >= 0) {
          childrenVal = parseInt(pathUri[vi].replace("-childrens-or-more", ""))
        }

        // Sort by
        if (pathUri[vi].indexOf("sortby-") >= 0) {
          const sortVal_filt = pathUri[vi].replace("sortby-", "")

          if (sortVal_filt === "") {
            sortVal = process.env.GATSBY_ALGOLIA_INDEX_NAME
          } else {
            sortVal = `${
              process.env.GATSBY_ALGOLIA_INDEX_NAME
            }_${sortVal_filt.replace(/-/g, "_")}`
          }

          // if (sortVal_filt === "price-asc") {
          //   sortVal = `${process.env.GATSBY_ALGOLIA_INDEX_NAME}_price_asc`
          // }

          // if (sortVal_filt === "price-desc") {
          //   sortVal = `${process.env.GATSBY_ALGOLIA_INDEX_NAME}_price_desc`
          // }
        }

        // Include Sold
        if (pathUri[vi].indexOf("includes-") >= 0) {
          const sortVal_filt = pathUri[vi].replace("includes-", "")
          soldVal = pathUri[vi].replace("includes-", "")
          if (
            propertyTypeVal === "residential" &&
            searchtypeVal === "sales" &&
            soldVal === "sold"
          ) {
            statusVal = propertyStatus[0].sales_sold
          }
          if (
            propertyTypeVal === "residential" &&
            searchtypeVal === "lettings" &&
            soldVal === "let-agreed"
          ) {
            statusVal = propertyStatus[1].lettings_agreed
          }
        }

        // Page
        if (pathUri[vi].indexOf("page") >= 0) {
          pageVal = pathUri[vi].replace("page-", "")
        }

        if (pathUri[vi].indexOf("map-view") >= 0) {
          layoutVal = "map"
        }
      }
    }
    const mydatarr = []
    mydatarr.indexVal = index_name
    mydatarr.departmentVal = departmentVal
    mydatarr.searchtypeVal = searchtypeVal
    mydatarr.areaVal = areaVal
    mydatarr.bedVal = bedVal
    mydatarr.bathVal = bathVal
    mydatarr.minpriceVal = minpriceVal
    mydatarr.maxpriceVal = maxpriceVal
    mydatarr.checkinVal = checkinVal
    mydatarr.checkoutVal = checkoutVal
    mydatarr.amenitiesVal = amenitiesVal
    mydatarr.adultVal = adultVal
    mydatarr.childrenVal = childrenVal
    mydatarr.pageVal = pageVal
    mydatarr.buildingval = buildingval
    mydatarr.sortVal = sortVal
    mydatarr.soldVal = soldVal
    mydatarr.layoutVal = layoutVal
    mydatarr.statusVal = statusVal
    mydatarr.propertyTypeVal = propertyTypeVal
    return mydatarr
  }
  return false
}

export default parseResultsUrl
